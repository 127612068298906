import { Column, Container } from '@123-front/ui-kit';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import CarLeadForm, { CarLeadData } from '../../../components/car-lead-form';
import FlexCenter from '../../../components/flex-center';
import Footer from '../../../components/footer';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import SuccessNotice from '../../../components/success-notice';

const defaultLead: CarLeadData = {
  car: '',
  email: '',
  name: '',
  phone: '',
};

const FeaturedLandingMx: React.FC = () => {
  const [submitted, submit] = useState(false);
  const [leadInfo, setLeadInfo] = useState(defaultLead);
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "photo_landing_mex_hero_mobile" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "photo_landing_mex_hero_desktop" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);

  return (
    <Layout gtmId="GTM-K39GH72">
      <SEO title="Cotizar Seguro de Auto" siteTitle="123Seguro México" />
      <Hero
        desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
        mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
        title="Cotizar Seguro de Auto"
        subtitle="Encuentra el mejor\nseguro de auto"
      />
      <Container>
        <div style={{ display: 'flex' }}>
          <FlexCenter>
            <Column desktop="6">
              {submitted ? (
                <SuccessNotice
                  noticeText={`Hemos recibido tu solicitud de cotización para tu ${leadInfo.car}. Nos contactaremos contigo por WhatsApp (${leadInfo.phone}) o por mail (${leadInfo.email}).`}
                  solicitudText="¡Solicitud enviada!"
                  verifyText="¿No son esos tus datos?"
                  onRequestChange={() => submit(false)}
                  changeText="Modificarlos"
                />
              ) : (
                <CarLeadForm
                  {...leadInfo}
                  sampleCar="Ej.: Nissan Versa 2019"
                  sampleName="Ej.: Ariel González"
                  sampleEmail="Ej.: nombre@dominio.com"
                  samplePhone="Ej.: 5566778899"
                  labelCar="MARCA, MODELO Y AÑO DE TU AUTO"
                  labelName="NOMBRE"
                  labelEmail="CORREO ELECTRÓNICO"
                  labelPhone="TELÉFONO CELULAR"
                  minPhoneLength={7}
                  maxPhoneLength={13}
                  countryCode="mx"
                  errorMessage="Por favor revisa los campos con error"
                  missingCarErrorMessage="Ingresa tu vehículo; ej.: Nissan Versa 2019"
                  missingNameErrorMessage="Ingresa tu nombre; ej.: Ariel González"
                  missingEmailErrorMessage="Ingresa tu email; ej.:nombre@dominio.com"
                  invalidEmailErrorMessage="Ingresa un email válido; ej.: nombre@dominio.com"
                  missingPhoneErrorMessage= "Ingresa tu teléfono; ej.: 5566778899"
                  invalidPhoneErrorMessage="Ingresa un teléfono válido; ej.: 5566778899"
                  focusEmailMessage="Correo al que enviaremos tu póliza"
                  focusPhoneMessage="Celular para la gestión de tu seguro"
                  submitbutton="Solicitar cotización"
                  onRegistration={(formValues) => {
                    setLeadInfo(formValues);
                    submit(true);
                  }}
                ></CarLeadForm>
              )}
            </Column>
          </FlexCenter>
        </div>
        <Footer />
      </Container>
    </Layout>
  );
};

export default FeaturedLandingMx;
